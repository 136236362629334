.item-s {
  font-size: 10pt;
  padding: 9px;
}
.title-item {
  border: solid 1px #259e3f;
  border-radius: 17px;
  padding: 2px 15px 2px 15px;
}
.basecontent {
  margin: auto;
  text-align: center;
  justify-content: center;
}
.lodings {
  margin-bottom: -140px;
}
.alertas {
  width: 362px;
}
.total-des {
  padding-top: 12px;
}
th.titles {
  padding-bottom: 14px;
}
.alertaprecio {
  padding: 10px;
  background: #2aff8d0a;
  width: 62%;
  margin: auto;
  text-align: center;
  border-radius: 10px;
  border: solid 1px #259e3f;
  display: flex;
}
.labelfp {
  padding-left: 10px;
}
.lg-slider {
  margin: auto;
}
.logo-login {
  width: 61%;
  margin: auto;
}
.logo-slide {
  height: 60px;
}
.totalResul {
  background-image: linear-gradient(45deg, #259e3f4f, #3b69b11f);
  width: 48%;
  margin: auto;
  padding: 16px;
  border-radius: 14px;
  box-shadow: 0px -1px 19px 3px #259e3f0d;
}
.titlebox {
  padding: 11px;
  font-size: 11pt;
  width: 80%;
  text-align: center;
  margin: auto;
  background: #7a9ac40f;
  border-radius: 16px;
}
.quetzal {
  background: #1483ff2e;
  padding: 8px 0px 8px 0px;
  border-radius: 11px;
  font-size: 10pt;
  text-align: center;
}
.div-filtro {
  padding-top: 32px;
  margin-top: 27px !important;
}
.dolar {
  background: #1483ff2e;
  padding: 8px 0px 8px 0px;
  border-radius: 11px;
  font-size: 10pt;
  text-align: center;
}
.btns {
  width: 100%;
  height: 52px;
}
.dolar {
  background: #24983d29;
  padding: 8px 0px 8px 0px;
  border-radius: 11px;
  font-size: 10pt;
  text-align: center;
}
.dolar-total {
  padding: 8px 0px 8px 0px;
  border-radius: 11px;
  font-size: 10pt;
  text-align: center;
  border: solid 1px #24983d47;
}
table {
  width: 100%;
}
h6#preciomax-\$ {
  display: none;
}
h6#preciomin-\$ {
  display: none;
}
h6#preciomax-Q {
  display: none;
}
h6#preciomin-Q {
  display: none;
}

@media screen and (max-width: 767px) {
  .component-table {
    display: flex;
    flex-direction: column !important;
  }
  .component-boxing {
    max-width: 100%! important;
}
}

/* Estilos para iPads */

@media screen and (min-width: 768px) and (max-width: 1023px) {
  /* Agrega estilos específicos para pantallas entre 768px y 1023px de ancho */
  .component-table {
    display: flex;
    flex-direction: column !important;
  }
  .component-boxing {
    max-width: 100%! important;
}
}

/* Estilos para laptops y pantallas más grandes */

@media screen and (min-width: 1024px) {
  /* Agrega estilos específicos para pantallas más grandes de 1024px de ancho */
}
